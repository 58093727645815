/* eslint-disable no-undef,no-console, camelcase */
import { toast } from 'react-toastify';
import _, { isUndefined } from 'lodash';

import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import { getListOfThings, getRewardPoints, getCompanyLocation, getCompanyDepartment, getUserDetails } from '../index';
import { checkAndUpdateRole } from '../authActions/index';

import {
  getUserMonthlyDetails, ViewPhysicianForm, FetchAmigoCountAPI, ChangePasswordAPI, UpdateProfileData, UpdateWellnessAPI, RemoveProfile, UploadProfile, GetUserProfile, SearchAmigos, SendFriendRequest, ThanksForTheTip,
  UpdateWellnessGoal, SetDailyGoalAPI, SaveWellbeingAssessment, UploadBiometricFormAPI, UpdateAcceptedLegalUpdates, updateCompanyUserData,
  updateMonthlyGoals, getListOfThingsToComplete, UpdateUserEmail, getshowUserInitiatives, GetUpcomingEvents, GetRecommenedThingsToComplete, GetMonthlyTasks, AddUserTask, GetUserPermissions, UpdateChallengeSurveyStatus,
  GetUserRecognitionsCount, GetMyUpcomingEvents, GetBirthdayList, GetWorkAnniversary, UpdateUserDetailsAPI, RejectFriendSuggestion,UserChallengeCompletedPopup, GetLiveEvents, GetuserInitiatives, PostuserInitiatives, GetUserChallengeInvitation, JoinOrLeaveChallenge, RemoveUserInviteForChallenge, GetUserBiometricData, GetWellbeingAssessmentDetailsForUser,
  ProfileRoles, ProfileCompanyDetails, ProfileActivityData, ProfileChallengeSurveys, ProfileInputActivityGoals, ProfileMonthlyGoals, ProfileThemes, ProfileThemesAll
} from '../../constants/apiConstants';

import { AmigosLimitPerPage } from '../../../utils/constants';
import moment from "moment";
import { getActivityDate, findMonth, getPermissionStatus } from "../../../utils/methods";
import { isNull } from 'lodash';
import { getSuggestionsList } from '../socialActions';

const getUserHealth = () => (dispatch) => dispatch({ type: actionTypes.GET_USER_HEALTH_DATA });

const userProfileData = (res) => (dispatch) => dispatch({ type: actionTypes.USER_PROFILE_DATA, response: res });

const getUserProfile = () => (dispatch) => dispatch({ type: actionTypes.GET_USER_DATA });

const errorOccurred = (error) => (dispatch) => dispatch({ type: actionTypes.FETCH_PROFILE_DATA_FAILED, error });

const networkError = (error) => (dispatch) => dispatch({ type: actionTypes.NETWORK_ERROR, networkError: error });

const getUserName = () => (dispatch) => dispatch({ type: actionTypes.GET_USER_NAME });

const getUserProfileImage = () => (dispatch) => dispatch({ type: actionTypes.GET_USER_PROFILE_IMAGE });

const getUserId = () => (dispatch) => dispatch({ type: actionTypes.GET_USER_ID });

const setUserId = (id) => (dispatch) => dispatch({ type: actionTypes.SET_USER_ID, id: id });

const getDailyTip = () => (dispatch) => dispatch({ type: actionTypes.GET_DAILY_TIP });

const getStatusForBiometricPopUp = () => (dispatch) => dispatch({ type: actionTypes.SET_BIOMETRIC_POPUP });

const getStatusForHRAPopUp = () => (dispatch) => dispatch({ type: actionTypes.SET_HRA_POPUP });

const getMotivationalQuote = () => (dispatch) => dispatch({ type: actionTypes.GET_MOTIVATIONAL_QUOTE });

const getDailyActivityData = () => (dispatch) => dispatch({ type: actionTypes.GET_DAILY_ACTIVITY_DATA });

const getWellnessGoals = () => (dispatch) => dispatch({ type: actionTypes.GET_WELLNESS_GOALS });

const getAchievedGoals = () => (dispatch) => dispatch({ type: actionTypes.GET_ACHIEVED_GOALS });

const getInputActivityGoals = () => (dispatch) => dispatch({ type: actionTypes.GET_INPUT_ACTIVITY_GOALS });

const getWellnessJourney = () => (dispatch) => dispatch({ type: actionTypes.GET_WELLNESS_JOURNEY });

const getWellnessVideo = () => (dispatch) => dispatch({ type: actionTypes.GET_WELLNESS_VIDEO });

const getWellnessAssessment = () => (dispatch) => dispatch({ type: actionTypes.GET_WELLNESS_ASSESSMENT });

const getUserCompanyDetails = () => (dispatch) => dispatch({ type: actionTypes.GET_COMPANY_FOR_USER });

const allowChallengeEventForSpouseDependent = () => (dispatch) => dispatch({ type: actionTypes.GET_COMPANY_WITH_SPOUSE_DEPENDENT });

const getCompletedChallenges = () => (dispatch) => dispatch({ type: actionTypes.GET_COMPLETED_CHALLENGES });

const getConnectedDevices = () => (dispatch) => dispatch({ type: actionTypes.GET_CONNECTED_DEVICES})

const getUser = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      // dispatch({ type: actionTypes.SET_COMPANY_LOCATION, locations: null });
      // dispatch({ type: actionTypes.SET_COMPANY_DEPARTMENT, department: null });
      const res = await http.get(GetUserProfile, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.SET_USER_AUTH, userAuth: res.auth_token });
        const profile = !res.user.profile_image ? 'profile-Image/default.png' : res.user.profile_image;
        res.user.profile_image = profile;
        // dispatch(checkAndUpdateRole(res))
        dispatch(userProfileData(res));
        //dispatch(getChallengeCompleted());
        dispatch({ type: actionTypes.SET_USER_FIRST_NAME, userName: res.user.fname });
        dispatch({ type: actionTypes.SET_USER_NAME, userName: (res.user.fname + ' ' + res.user.lname) });
        dispatch({ type: actionTypes.SET_USER_PROFILE_IMAGE, profileImage: profile });
        dispatch(setUserId(res.user.uid));
        dispatch({ type: actionTypes.SET_DAILY_TIP, dailyTip: res.daily_tip });
        dispatch({ type: actionTypes.SET_MOTIVATIONAL_QUOTE, motivationalQuote: res.motivational_quote });
        // dispatch({ type: actionTypes.SET_DAILY_ACTIVITY_DATA, dailyActivityData: res.activity_data });
        dispatch({ type: actionTypes.SET_WELLNESS_GOALS, wellnessGoals: res.goal });
        dispatch({ type: actionTypes.SET_ACHIEVED_GOALS, achievedGoals: res.reward });
        dispatch({ type: actionTypes.SET_USER_DATA, user: res.user });
        dispatch({ type: actionTypes.SET_USER_HEALTH_DATA, health: res.health });
        dispatch({ type: actionTypes.SET_INTEREST_DATA, interest: res.interest });
        // dispatch({ type: actionTypes.SET_INPUT_ACTIVITY_GOALS, inputGoals: res.input_activity_goals });
        dispatch({ type: actionTypes.SET_WELLNESS_JOURNEY, wellnessJourney: res.wellness_journey });
        dispatch({ type: actionTypes.SET_WELLNESS_VIDEO, wellnessVideo: res.wellness_video });
        dispatch({ type: actionTypes.SET_WELLNESS_ASSESSMENT, wellnessAssessment: res.wellbeing_assessment });
        // dispatch({ type: actionTypes.SET_COMPANY_FOR_USER, id: res.user.company_id, name: res.user.company_name, showBiometricStatus: res.user.show_biometric });
        // dispatch({ type: actionTypes.SET_COMPANY_LOGO, logoDetails: res.company_details });
        // dispatch({ type: actionTypes.CHALLENGE_EVENT_FOR_SPOUSE_DEPENDENT, companyDetails: res.company_details });
        dispatch({ type: actionTypes.SET_COMPLETED_CHALLENGES, completedChallenges: res.complete_challenges });
        dispatch({ type: actionTypes.SET_DEVICE_DISCONNECTION, devicesDisconnected: res.disconnect_devices });
        dispatch({ type: actionTypes.SET_CONNECTED_DEVICES, connectedDevices: res.connected_Devices});
        // dispatch({ type: actionTypes.SET_MONTHLY_GOAL, monthlyGoal: res.monthly_goals });
        // dispatch({ type: actionTypes.SET_THEMES_HEADER, themesHeader: res.themes });
        // dispatch({ type: actionTypes.SET_CHALLENGE_SURVEYS, challengeSurveys: res.challenge_surveys });
        dispatch({ type: actionTypes.SET_BIOMETRIC_POPUP, showBiometricPopUp: res.show_biometric_popup });
        dispatch({ type: actionTypes.SET_HRA_POPUP, showHRAPopUp: res.show_hra_popup });
        if (!isNull(localStorage.getItem('AUTH_TOKEN'))) {
          dispatch(getWellnessInitiativeAction());
          if (res.user.company_id) {
            dispatch(getCompanyLocation(res.user.company_id));
            dispatch(getCompanyDepartment(res.user.company_id));
          }
          // dispatch(getUserPermissions());
        }
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getChallengeCompleted = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(UserChallengeCompletedPopup, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.USER_CHALLENGE_COMPLETED_POPUP, completedPopupData: res.complete_challenges });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};


const getGoals = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileInputActivityGoals, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.SET_INPUT_ACTIVITY_GOALS, inputGoals: res.input_activity_goals });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getWellnessInitiativeAction = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(getshowUserInitiatives, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_USER_WELLNESS_INITIATIVE, value: res })
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const saveSearchAmigoInfo = (name, value) => (dispatch) => dispatch({ type: actionTypes.SAVE_SEARCH_AMIGO_INFO, name: name, value: value });
const clearSearchAmigoInfo = () => (dispatch) => dispatch({ type: actionTypes.CLEAR_SEARCH_AMIGO_INFO });
const clearSearchAmigoList = () => (dispatch) => dispatch({ type: actionTypes.CLEAR_SEARCH_AMIGO_LIST });

const searchAmigos = (pageNumber, checked) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    const search = {
      first_name: _.get(getState().profileData.searchAmigo, 'firstName', ''),
      last_name: _.get(getState().profileData.searchAmigo, 'lastName', ''),
      min_age: _.get(getState().profileData.searchAmigo, 'minAge', ''),
      max_age: _.get(getState().profileData.searchAmigo, 'maxAge', ''),
      state: _.get(getState().profileData.searchAmigo, 'state', ''),
      country: _.get(getState().profileData.searchAmigo, 'city', ''),
      interest: _.get(getState().profileData.searchAmigo, 'interest', ''),
      goals: _.get(getState().profileData.searchAmigo, 'goals', ''),
      lat: '',
      long: '',
      distance: '',
      page_no: pageNumber,
      limit: AmigosLimitPerPage
    };

    if (checked) {
      search['company_id'] = _.get(getState().profileData.user, 'company_id', '') || 0
    }

    try {
      const res = await http.post(SearchAmigos, search, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        res.message && toast.error(res.message);
        dispatch({ type: actionTypes.FAILED_SEARCH_AMIGO, searchAmigoError: res.message });
      } else {
        dispatch({ type: actionTypes.SEARCH_AMIGO, amigoList: res, pageNumber: pageNumber });
        if (pageNumber === 1) {
          dispatch(FetchSearchAmigoCount(search));
        }
        dispatch(setPageNumber());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const FetchSearchAmigoCount = (search) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(FetchAmigoCountAPI, search, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.count) {
        res.message && toast.error(res.message);
        dispatch({ type: actionTypes.FAILED_SEARCH_AMIGO, searchAmigoError: res.message });
      } else {
        dispatch({ type: actionTypes.SET_AMIGO_COUNT, count: res.count });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const setPageNumber = () => (dispatch, getState) => dispatch({ type: actionTypes.SET_PAGE_NUMBER, pageNumber: getState().profileData.pageNumber + 1 });

const getPageNumber = () => (dispatch) => dispatch({ type: actionTypes.GET_PAGE_NUMBER });

const updateAmigo = (id) => (dispatch) => dispatch({ type: actionTypes.UPDATE_AMIGO, id: id });

const sendFriendRequest = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const sendRequest = {
      invitee_id: id,
      status: 'Request_sent'
    };
    try {
      const res = await http.post(SendFriendRequest, sendRequest, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch({ type: actionTypes.FAILED_SEND_FRIEND_REQUEST, sendRequestError: res.message });
      } else {
        toast.success(res[0]);
        dispatch(updateAmigo(id));
        dispatch({ type: actionTypes.SEND_FRIEND_REQUEST, requestSent: res[0] });
      }
    } catch (error) {
      dispatch(networkError(error.message));
      dispatch(getSuggestionsList())
      // window.location.reload();
    }
  }
};

const thanksForTheTipAPI = (id, status) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');

  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    let res;
    try {
      res = await (status === '' || isUndefined(status) ? http.get(`${ThanksForTheTip}/${id}`, { headers: headers }) : http.get(`${ThanksForTheTip}/${id}/${status}`, { headers: headers }));
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.UPDATE_DAILY_TIP, show_tip: false });
        dispatch(getRewardPoints());
        dispatch(getUser());
        let currentMonth = moment().startOf("month").format('MMMM');
        let currentYear = moment().startOf('year').format('YYYY');
        let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
        let startDate = moment(obj.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
        dispatch(getUseronthlyDetailsAPI(startDate));
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const updateWellnessGoal = (goalDetails, updateGoal) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.put(UpdateWellnessGoal, { ...updateGoal }, { headers: headers });
      if (res[0]) {
        dispatch({ type: actionTypes.UPDATE_WELLNESS_GOALS, goals: goalDetails })
      }
      else {
        toast.error(res.message)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
};

const SetDailyGoal = (dailyGoalData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.put(SetDailyGoalAPI, { ...dailyGoalData }, { headers: headers });
      if (res[0]) {
        dispatch({ type: actionTypes.SET_DAILY_GOAL, dailyGoal: dailyGoalData })
      }
      else {
        toast.error(res.message)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
};

const uploadProfile = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.post(UploadProfile, { ...data }, { headers: headers })
      if (res[0]) {
        toast.success(res[0]);
        dispatch(getListOfThings());
        dispatch({ type: actionTypes.SET_USER_PROFILE_IMAGE, profileImage: data.profile_image });
        dispatch({ type: actionTypes.UPLOAD_PROFILE_PICTURE, profile_image: data.profile_image });
      }
      else {
        toast.error(res.message)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
};

const removeProfileAPI = (data, callback) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.post(RemoveProfile, { ...data }, { headers: headers });
      if (res[0]) {
        toast.success(res[0]);
        dispatch({ type: actionTypes.UPLOAD_PROFILE_PICTURE, profile_image: 'profile-Image/default.png' });
        callback('');
      }
      else {
        toast.error(res.message);
        callback('');
      }
    }
    catch (error) {
      toast.error(error);
      callback('');
    }
  }
};
const putUpdateUserData = (updateUserDetail, callback) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const postData = {
    ...updateUserDetail
  };
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateProfileData, postData, config);
      if (res.message) {
        toast.error(res.message);
        callback('');
        dispatch(getUser());
      } else {
        toast.success(res[0]);
        callback('');
        dispatch(getUser());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      callback('');
      window.location.reload();
    }
  }
};

const putCompanyAndDepartment = (updateUserDetail) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const data = {
    ...updateUserDetail
  };
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(updateCompanyUserData, data, config);
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getUser())
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};


const putUpdateWellnessData = (updatewellnessDetail, callback) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const postData = {
    ...updatewellnessDetail
  };
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateWellnessAPI, postData, config);
      if (res.message) {
        toast.error(res.message);
        callback('');
      } else {
        toast.success(res[0]);
        callback('');
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      callback('');
      window.location.reload();
    }
  }
};

const saveWellbeingAssessment = (history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };

    let medical = getState().wellBeingAssessment.Medical;
    medical.push(_.sum(_.map(medical, 'count')));
    let lifestyle = getState().wellBeingAssessment.Lifestyle;
    lifestyle.push(_.sum(_.map(lifestyle, 'count')));
    let fitness = getState().wellBeingAssessment.Fitness;
    fitness.push(_.sum(_.map(fitness, 'count')));
    let nutrition = getState().wellBeingAssessment.Nutrition;
    nutrition.push(_.sum(_.map(nutrition, 'count')));
    let stress = getState().wellBeingAssessment.Stress;
    stress.push(_.sum(_.map(stress, 'count')));
    let workplace = getState().wellBeingAssessment.Workplace;
    workplace.push(_.sum(_.map(workplace, 'count')));

    const wellbeingAssessment = { medical, lifestyle, fitness, nutrition, stress, workplace };

    let wellnessAssessment = {
      fitness: _.sum(_.map(fitness, 'count')),
      lifestyle: _.sum(_.map(lifestyle, 'count')),
      nutrition: _.sum(_.map(nutrition, 'count')),
      stress: _.sum(_.map(stress, 'count')),
      workplace: _.sum(_.map(workplace, 'count')),
      is_wba_taken: 1
    };

    try {
      const res = await http.post(SaveWellbeingAssessment, wellbeingAssessment, config);
      if (!res.data) {
        toast.error(res.message);
        dispatch({ type: actionTypes.SAVE_WELLBEING_ASSESSMENT_ERROR, error: res.message });
      } else {
        toast.success(res.message);
        wellnessAssessment.points = res?.points;
        wellnessAssessment.data = res?.data;
        dispatch({ type: actionTypes.SET_WELLNESS_ASSESSMENT, wellnessAssessment: wellnessAssessment });
        dispatch({ type: actionTypes.SAVE_WELLBEING_ASSESSMENT_SUCCESS, success: res.message });
        history.push('/wellness-assessment/score');
        dispatch({ type: actionTypes.CLEAR_WELLBEING_ASSESSMENT });
        dispatch(getListOfThings());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const changePassword = (resetData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(ChangePasswordAPI, resetData, config);
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const changeEmail = (resetData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateUserEmail, resetData, config);
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const uploadBiometricForm = (data, cb, id, fetchEmployee) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    let formAPIUrl = '';
    formAPIUrl = !id ? UploadBiometricFormAPI : `${UploadBiometricFormAPI}/${id}`;
    try {
      const res = await http.post(`${formAPIUrl}`, { ...data }, { headers: headers })
      if (res[0]) {
        toast.success(res[0]);
        dispatch(getUser());
        if (fetchEmployee) {
          fetchEmployee();
        }
      }
      else {
        toast.error(res.message)
      }
      cb(false);
    }
    catch (error) {
      toast.error(error.message);
      cb(false);
    }
  }
};

const updateLegalUpdatesAPI = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  let data = {
    "accepted_privacy_policy": 1
  }
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.put(UpdateAcceptedLegalUpdates, data, { headers: headers });
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getUser())
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const viewPhysicianFormAPI = (uid, cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');

  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${ViewPhysicianForm}/${uid}`, { headers: headers });
      if (res.message) {
        toast.error(res.message);
      } else {
        if (cb) {
          cb(false);
          const linkSource = res.physician_form;
          const downloadLink = document.createElement("a");
          const fileName = "physician_form.pdf";
          downloadLink.download = fileName;
          downloadLink.target = '_blank';
          downloadLink.href = linkSource;
          document.getElementById('app').appendChild(downloadLink);
          downloadLink.click();
        }
        dispatch({ type: actionTypes.SET_BIOMETRIC_FORM_DATA, biometric_form_pdf: res.physician_form });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const getUseronthlyDetailsAPI = (date) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${getUserMonthlyDetails}/${date}`, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.SET_USER_MONTHLY_DETAILS, value: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const setMonthlyGoal = (monthlyGoalData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async () => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.put(updateMonthlyGoals, { ...monthlyGoalData }, { headers: headers });
      if (res[0]) {
        toast.success(res[0]);
        // dispatch({type: actionTypes.SET_MONTHLY_GOAL, monthlyGoal:res[0]})
      }
      else {
        toast.error(res.message)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
};

const getListOfThingsToCompleteAPI = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${getListOfThingsToComplete}`, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.SET_LIST_OF_THINGS_TO_COMPLETE, value: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const getUpcomingEvents = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetUpcomingEvents, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.UPCOMING_EVENT, upComingEvent: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const getRecommenedThingsToComplete = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetRecommenedThingsToComplete, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.GET_RECOMMENDED_THINGS_TO_COMPLETE, slideShowThings: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const getMonthlyTasks = (month) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${GetMonthlyTasks}/${month}`, { headers: headers });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_MONTHLY_TASK_LIST, monthlyCheckList: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};
const updateMonthlyChecklistTask = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(AddUserTask, obj, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getMonthlyTasks(findMonth(new Date().getMonth()).toLowerCase()));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getUserPermissions = () => {
  const AuthToken = localStorage?.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetUserPermissions, { headers: headers });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_USER_PERMISSIONS_LIST, userPermissions: res });
        localStorage.setItem('show_admin', getPermissionStatus("Access admin portal", res));
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};
const updateChallengeSurveyStatus = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateChallengeSurveyStatus, obj, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch(getUser());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserRecognitionsCount = (uid) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserRecognitionsCount}/${uid}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_USER_RECOGNITION_COUNT, userRecognitionCount: res })
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const getMyUpcomingEvents = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetMyUpcomingEvents, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.GET_MY_UPCOMING_EVENTS, myUpcomingEvents: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
}

const getLiveEvents = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetLiveEvents, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.GET_LIVE_EVENTS, liveEvents: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
}

const getBirthdaysList = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetBirthdayList, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      }
      else {
        dispatch({ type: actionTypes.GET_BIRTHDAYS_LIST, birthdayList: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
}

const getWorkAnniversary = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetWorkAnniversary, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.GET_WORK_ANNIVERSARY_LIST, workAnniversaryList: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};
const UpdateUserDetails = (userDetailsData, uid) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.put(UpdateUserDetailsAPI, { ...userDetailsData }, { headers: headers });
      if (res[0]) {
        dispatch(getUserDetails(uid));
        toast.success(res[0]);
        dispatch({ type: actionTypes.UPDATE_USER_DETAILS, userDetails: userDetailsData });
      }
      else {
        toast.error(res.message)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
};

const getRejectFriendSuggestion = (uid) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${RejectFriendSuggestion}/${uid}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res) {
        dispatch({ type: actionTypes.REJECT_FRIEND_REQUEST, rejectFriendRequest: res })
        dispatch(getSuggestionsList());
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const getUserCompanyItitiatives = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${GetuserInitiatives}`, { headers: headers });
      if (res.message) {
        toast.error(res.message)
      } else {
        dispatch({ type: actionTypes.GET_USER_INITIATIVES, userInitiatives: res });
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
};

const postUserInitiatives = (initiativesData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(PostuserInitiatives, initiativesData, config);
      if (res.message) {
        toast.error(res.message);
        dispatch({ type: actionTypes.FAILED_USER_INITIATIVES, createUserInitiativesError: res.message });
      } else {
        toast.success(res[0]);
        dispatch({ type: actionTypes.SUCCESSFULLY_USER_INITIATIVES, userInitiativesTrue: res[0] });
        dispatch(getUserCompanyItitiatives());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getUserChallengeInvitationAPI = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${GetUserChallengeInvitation}`, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.USER_CHALLENGE_INVITATION, challengeInvitation: res});
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const joinOrLeaveChallenge = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.CHALLENGE_LOADING});
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(JoinOrLeaveChallenge, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.JOIN_LEAVE_CHALLENGE_FAILED, joinOrLeaveError: res.message});
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.JOIN_LEAVE_CHALLENGE_SUCCESS, success: res[0]});
        dispatch(getUserChallengeInvitationAPI());
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const getRemoveUserInviteForChallenge = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(RemoveUserInviteForChallenge, data, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.REMOVE_USER_CHALLENGE_INVITATION, successInviteMessage: res[0]});
        dispatch(getUserChallengeInvitationAPI());
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

const getUserBiometric = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetUserBiometricData, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_BIOMETRIC_DATA, userBiometric: res[0] });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserWellbeingAssessmentDetails = (year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWellbeingAssessmentDetailsForUser}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GETUSER_WELLBEING_ASSESSMENT_DETAILS, userWbaWellbeing: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserWellbeingAssessmentDetailsV2 = (year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWellbeingAssessmentDetailsForUser}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GETUSER_WELLBEING_ASSESSMENT_DETAILSV2, userWbaWellbeingV2: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getProfileRoles = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileRoles, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch(checkAndUpdateRole(res))
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileCompanyDetails = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileCompanyDetails, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_COMPANY_LOGO, logoDetails: res.company_details });
        dispatch({ type: actionTypes.CHALLENGE_EVENT_FOR_SPOUSE_DEPENDENT, companyDetails: res.company_details });
        dispatch({ type: actionTypes.SET_COMPANY_FOR_USER, companyStartDate: res.company_details, id: res.company_details['id'], name: res.company_details['company_name'], showBiometricStatus: res.company_details['show_biometric'], showTeam: res.company_details['is_team_active']});
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileActivityData = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileActivityData, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_DAILY_ACTIVITY_DATA, dailyActivityData: res.activity_data });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileChallengeSurveys = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileChallengeSurveys, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_CHALLENGE_SURVEYS, challengeSurveys: res.challenge_surveys });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileInputActivityGoals = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileInputActivityGoals, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_INPUT_ACTIVITY_GOALS, inputGoals: res.input_activity_goals });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileMonthlyGoals = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileMonthlyGoals, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_MONTHLY_GOAL, monthlyGoal: res.monthly_goals });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileThemes = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileThemes, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_THEMES_HEADER, themesHeader: res.themes[0] });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileAllThemes = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(ProfileThemesAll, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_ALL_THEMES_HEADER, themesHeaderAll: res.all_themes });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getProfileCompanyDetailsById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${ProfileCompanyDetails}/${id}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SET_COMPANY_LOGO, logoDetails: res.company_details });
        dispatch({ type: actionTypes.CHALLENGE_EVENT_FOR_SPOUSE_DEPENDENT, companyDetails: res.company_details });
        dispatch({ type: actionTypes.SET_COMPANY_FOR_USER, companyStartDate: res.company_details, id: res.company_details['id'], name: res.company_details['company_name'], showBiometricStatus: res.company_details['show_biometric'], showTeam: res.company_details['is_team_active']});
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

export { getListOfThingsToCompleteAPI, setMonthlyGoal, getUseronthlyDetailsAPI, putCompanyAndDepartment, updateLegalUpdatesAPI, uploadBiometricForm,
  changePassword, putUpdateWellnessData, putUpdateUserData, getUser, getUserName, getUserProfileImage, getUserId, getDailyTip, getMotivationalQuote,
  getDailyActivityData, getWellnessGoals, getAchievedGoals, saveSearchAmigoInfo, clearSearchAmigoInfo, clearSearchAmigoList, searchAmigos, getPageNumber,
  sendFriendRequest, getUserProfile, getUserHealth, thanksForTheTipAPI, updateWellnessGoal, SetDailyGoal, getInputActivityGoals, getWellnessJourney, getWellnessVideo,
  uploadProfile, removeProfileAPI, getWellnessAssessment, saveWellbeingAssessment, getUserCompanyDetails, changeEmail, getGoals, allowChallengeEventForSpouseDependent,
  getCompletedChallenges, getUpcomingEvents, getRecommenedThingsToComplete, viewPhysicianFormAPI, getWellnessInitiativeAction, getMonthlyTasks,
  updateMonthlyChecklistTask, getUserPermissions, updateChallengeSurveyStatus, getStatusForBiometricPopUp, getUserRecognitionsCount, getMyUpcomingEvents, getWorkAnniversary,
  getBirthdaysList, getStatusForHRAPopUp, UpdateUserDetails, getRejectFriendSuggestion,getChallengeCompleted, getLiveEvents, getUserCompanyItitiatives, postUserInitiatives, getUserChallengeInvitationAPI, joinOrLeaveChallenge, getRemoveUserInviteForChallenge, getConnectedDevices, getUserBiometric, getUserWellbeingAssessmentDetails, getUserWellbeingAssessmentDetailsV2,
  getProfileRoles, getProfileCompanyDetails, getProfileActivityData, getProfileChallengeSurveys, getProfileInputActivityGoals, getProfileMonthlyGoals, getProfileThemes, getProfileAllThemes, getProfileCompanyDetailsById
};


